import {
  cilList,
  cilPeople,
  cilPlus,
  cilSearch,
  cilSpeedometer,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CNavItem } from "@coreui/react";

const adminNavItems = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Members List",
    to: "/admin/members-list",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Horse List",
    to: "/admin/horse-list",
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name:"Dashboard Content",
    to: "/admin/add-content",
    icon: <CIcon icon={cilPlus} customClassName="nav-icon" />,
  }

  //Add navigation items here
];

const ownerNavItems = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "My Horses",
    to: "/horse-list",
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "My Profile",
    to: "/profile",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Search Horses",
    to: "/search-all-horses",
    icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
  },
  //Add navigation items here
];

export { adminNavItems, ownerNavItems };
