import { lazy } from "react";
import AdminForgetPassword from "src/views/pages/login/AdminForgetPassword";
// const Dashboard = lazy(() => import("src/views/dashboard/Dashboard"));
const AdminLogin = lazy(() => import("src/views/pages/login/AdminLogin"));
const HorseList = lazy(() => import("src/views/horse-list/HorseList"));
const OwnersList = lazy(() => import("src/views/owners-list/OwnersList"));
const AddContent = lazy(() => import("src/views/add-content/AddContent"));

export const adminRoutes = [
  {
    path: "/admin/login",
    name: "Login",
    element: <AdminLogin />,
    authPage: true,
  },
  {
    path: "/admin/horse-list",
    name: "Horse List",
    element: <HorseList />,
    privatePage: true,
  },
  {
    path: "/admin/members-list",
    name: "Members List",
    element: <OwnersList />,
    privatePage: true,
  },
  {
    path: "/admin/add-content",
    name: "Dashboard Content",
    element: <AddContent />,
    privatePage: true
  },
  {
    path: "/admin/forget-password",
    name: "Forget Password",
    element: <AdminForgetPassword />,
    authPage: true,
  },
].map((data) => ({ ...data, adminRoute: true }));
