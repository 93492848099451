export const constants = {
  baseUrl: "https://api.registry.horse/api/",/* temp baseUrl ... It should be in .env file*/
  downloadBaseUrl: "https://api.registry.horse/",/* temp baseUrl ... It should be in .env file*/
  imgBaseUrl: "https://api.registry.horse/uploads/",
  accessToken: "accessToken",
  userRole: "userRole",
  userData: "userData",
  endPoints: {
    adminEndPoints: {
      adminLogin: "auth/admin-login",
      createOwner: "admin/members",
      editOwner: "admin/members",
      deleteOwner: "admin/members",
      ownersList: "admin/members/search",
      adminCreateHorse: "admin/horses",
      adminEditHorse: "admin/horses",
      adminDeleteHorse: "admin/horses",
      horseList: "admin/horses/search",
      statistics: "admin/statistics",
      horseRequestsList: "admin/pending-horses",
      memberActiveDeactivate: "admin/member",
      changeOwner: "admin/transfer-horse-ownership",
      sendCredential: "admin/send-membercredentials",
      // for changing the password
      adminOtpPassword: "admin/sendotp-password",

      adminSendOtp: 'admin/verifyOtp-updatepassword',
      adminChangePassword: "admin/change-adminPassword",
      // for changing the the email address

      adminEmailOtpSend: "admin/sendotp",
      adminChangeEmail: '/admin/verifyOtp-updateEmail',
      updatedashboardcontent: '/admin/dashboard-content', //this is put request for update
      getdashboardcontent: 'admin/dashboard/content' // this is get content for the admin

    },
    ownerEndPoints: {
      ownerLogin: "auth/login",
      otpLogin: "members/sendotp",
      horseList: "auth/horses",
      searchHorse: "auth/horses/search",
      ownerCreateHorse: "/horses",
      horseRequestsList: "auth/pending-horses",
      editOwner: "members/profile",
      // for changing the password
      ownerOtpPassword: "members/sendotp-password",

      ownerSendOtp: 'members/verifyOtp-updatepassword',
      ownerChangePassword: "members/change-memberPassword",
      ownerdashboardcontent: "members/dashboard/content"
    },
  },
};
