import { constants } from "src/constant/constants";
import { getRequest, postRequest, putRequest } from "../helper";

export const getOwnersList = async (filterParams) => {
  try {
    const res = await getRequest(
      constants.endPoints.adminEndPoints.ownersList,
      filterParams
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const putMemberActiveDeactivate = async (isActive, _id) => {
  try {
    const memberStatusFlag = isActive ? "deactivate" : "activate";
    const res = await putRequest(
      constants.endPoints.adminEndPoints.memberActiveDeactivate +
        `/${_id}/${memberStatusFlag}`
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const createOwner = async (payload) => {
  try {
    const res = await postRequest(
      constants.endPoints.adminEndPoints.createOwner,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const handleProfileEdit = async (payload) => {
  try {
    const res = await postRequest(
      constants.endPoints.ownerEndPoints.editOwner,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const sendRequestOtpOwner = async (payload) => {
  try {
    const res = await postRequest(
      `${constants.endPoints.ownerEndPoints.ownerOtpPassword}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const sendOtpForOwnerPass = async (payload) => {
  try {
    const res = await postRequest(
      `${constants.endPoints.ownerEndPoints.ownerSendOtp}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const changePasswordForOwner = async (payload) => {
  try {
    const res = await putRequest(
      `${constants.endPoints.ownerEndPoints.ownerChangePassword}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const GetOwnerDashboardContent = async () => {
  try {
    const res = await getRequest(
      constants.endPoints.ownerEndPoints.ownerdashboardcontent
    )
    return res.data;

  } catch(e) {
    throw e;
  }
};


